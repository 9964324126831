var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "control-table-footer df jc-sb ai-c" }, [
    _c(
      "div",
      {
        staticClass:
          "control-table-footer__count-car df ai-fe fs-10 ttu ls-5 fw-5",
      },
      [
        _c("span", { staticClass: "control-table-footer__text m-r-16" }, [
          _vm._v(_vm._s(_vm.textQuantity)),
        ]),
        _c("span", { staticClass: "control-table-footer__count fs-12 fw-b" }, [
          _vm._v(" " + _vm._s(_vm.paginationConfig.totalCount) + " "),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "control-table-footer__control df ai-c fs-12 ls-5 fw-5" },
      [
        _c(
          "div",
          { staticClass: "control-table-footer__btns" },
          [
            _vm.paginationConfig
              ? _c("IqPagination", {
                  attrs: {
                    "page-size": _vm.paginationConfig.perPage,
                    "page-count": _vm.paginationConfig.pageCount,
                    "pager-count": _vm.pager,
                    "current-page": _vm.paginationConfig.currentPage,
                    total: _vm.paginationConfig.totalCount,
                  },
                  on: { onChangePage: _vm.changePage },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "control-table-footer__select" },
          [
            _c("div", { staticClass: "m-r-9 fw-5" }, [
              _vm._v("На одной странице:"),
            ]),
            _c("IqSelect", {
              staticClass: "control-table-footer__select-input",
              attrs: {
                options: _vm.perPageList,
                "key-label": "label",
                "key-value": "value",
              },
              model: {
                value: _vm.perPage,
                callback: function ($$v) {
                  _vm.perPage = _vm._n($$v)
                },
                expression: "perPage",
              },
            }),
            _c("div", { staticClass: "m-l-9" }, [
              _vm._v(_vm._s(_vm.itemNames)),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }